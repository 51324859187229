<uni-toolbar [config]="toolbarconfig" [saveactions]="saveactions"> </uni-toolbar>

<section class="application uni-query-application" *ngIf="queryDefinition">
    <div class="model-container" *ngIf="hideModel === false">
        <div class="modeltreeview">
            <model-tree-view
                [header]="'Modeller/felter'"
                [mainModelName]="queryDefinition.MainModelName"
                [selectedFields]="fields"
                [showAllFields]="showAllFields"
                [showAllModels]="showAllModels"
                (fieldAdded)="addOrRemoveFieldFromChild($event)"
            >
            </model-tree-view>
        </div>
    </div>

    <div class="startinfo-container" *ngIf="fields.length === 0">
        <h1>Sett opp din egen rapport!</h1>
        <p>
            Her har du full tilgang til alle data i løsningen, og kan på den måten lage dine egne rapporter. Hvis du vil
            kan du lagre rapportene, slik at du enkelt kan hente de ut på ny senere - eller dele uttrekkene med andre.
        </p>
        <p>Hvis du vil jobbe videre med dataene kan du enkelt eksportere dem til Excel.</p>
        <p>Klikk på feltene i trestrukturen til venstre, og du er i gang med å lage din egen rapport!</p>
        <h2>Tips og triks!</h2>
        <p>
            Hvis du vil sette opp en rapport med forhåndsutfylte filtre kan du også legge inn dynamiske verdier, altså
            verdier som tilpasses ut fra hvem som er pålogget, regnskapsår og lignende. Legg i så fall inn en parameter
            der verdi settes til en av følgende (viktig å ha med ":" før navnet):
        </p>
        <ul>
            <li>
                <b>:currentuserid</b> - hvis du sammenligner med f.eks. CreatedBy får du opp entiteter opprettet av
                pålogget bruker
            </li>
            <li>
                <b>:externalid</b> - brukes når UniQuery kjøres i en annen context, f.eks. i et kundedetaljbildet.
                ":externalid" byttes da ut med f.eks. kundeid
            </li>
        </ul>
    </div>
    <label *ngIf="showExternalID && !externalID" class="uniquery-externalid-specification"
        >Angi evt. verdi for :externalid her:
        <input type="text" [(ngModel)]="customExternalID" (change)="customExternalIdChanged()" />
    </label>
    <section
        [ngClass]="{ 'table-container': !hideModel, 'table-container-full': hideModel }"
        *ngIf="tableConfig && fields.length > 0"
    >
        <ag-grid-wrapper
            class="transquery-grid-font-size"
            [resource]="lookupFunction"
            [config]="tableConfig"
            (columnsChange)="onColumnsChange($event)"
            (filtersChange)="onFiltersChange($event)"
            (rowClick)="onRowSelected($event)"
        >
        </ag-grid-wrapper>
    </section>
</section>

<save-query-definition-modal (saved)="onSaved($event)"></save-query-definition-modal>

<section class="placeholder-bottom"></section>
