<uni-toolbar [config]="{ title: 'Oversikt over uttrekk' }" [saveactions]="toolbarActions"> </uni-toolbar>
<section class="query_overview_page application">
    <section class="query_categories_container">
        <article class="query_group_card" *ngFor="let queryCategory of queryCategories">
            <h2>{{ queryCategory.name }}</h2>
            <div *ngFor="let uniQuery of queryCategory.queries">
                <a [routerLink]="['../details/' + uniQuery.ID]">
                    <h4 class="report_name">{{ uniQuery.Name }}</h4>
                    <p>{{ uniQuery.Description }}</p>
                </a>
            </div>
        </article>
    </section>
</section>
<router-outlet></router-outlet>
